import Vue from 'vue'
import Vuex from 'vuex'
import api from '../api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    apiKey: null,
    assetsUrl: null,
    currentDetailItem: null,
    detailVisible: false,
    loading: true,
    mapBounds: null,
    mapOptions: {},
    filterModel: null, /* current filter state */
    filterVisible: false,
    tileServer: null,
    primaryList: {}, /* represents the locations shown on the map */
    provider: 'osm', /* default is openstreetmaps, can be set to 'google' for gmaps via data-provider on rootEl */
    secondaryList: {} /* These items are only shown in detailview
                           when the corrosponding route is called */
  },
  mutations: {
    initialize (state, locations) {
      const primaryList = locations.defaultlist
      let itemIndex = 0
      locations.lists[primaryList].$items.forEach((location: any) => {
        location.itemIndex = itemIndex++
        // add locations to primaryList
        // add a 'visible' marker to each Location
        // if coordinates are not zero
        location.hasValidCoords = (location.longitude !== null && location.latitude !== null)
        location.isVisible = location.hasValidCoords
        // add location in easy to use format
        if (location.hasValidCoords) {
          location.position = {
            lng: location.longitude,
            lat: location.latitude
          }
        }
        Vue.set(state.primaryList, location.itemIndex, location)
      })
      state.filterModel = locations.lists[primaryList].filter

      // add all items of the remaining lists to the store
      // list-association is lost, only uid remains
      for (const listIndex in locations.lists) {
        if (listIndex !== primaryList) {
          const list = locations.lists[listIndex]
          list.$items.forEach((element: any) => {
            Vue.set(state.secondaryList, element.uid, element)
          })
        }
      }
    },
    toggleFilterVisible (state) {
      state.filterVisible = !state.filterVisible
    },
    removeLoader (state) {
      state.loading = false
    },
    detailVisible (state, isVisible) {
      state.detailVisible = isVisible
    },
    currentDetailItem (state, item) {
      state.currentDetailItem = item
    },
    setAssetsUrl (state, item) {
      state.assetsUrl = item
    },
    setProvider (state, item) {
      state.provider = item
    },
    setApiKey (state, item) {
      state.apiKey = item
    },
    setMapBounds (state, bounds) {
      state.mapBounds = bounds
    },
    setMapOptions (state, options) {
      state.mapOptions = options
    },
    setTileServer (state, url) {
      state.tileServer = url
    },
    updateItem (state, item) {
      Vue.set(state.primaryList, item.itemIndex, item)
    },

    // set filterModel and do the actual filtering
    // of primaryList
    setSelectedFilters (state, filterModel) {
      state.filterModel = filterModel
      const mustMatch: any = {}

      // add all fields that have checkboxes set to mustMatch
      Object.values(filterModel).forEach(
        function (filterField: any) {
          if (filterField.$type === 'Checkbox' && filterField.selected.length) {
            mustMatch[filterField.targetProperty] = filterField.selected
          }
          if (filterField.$type !== 'Checkbox' && filterField.$value !== '') {
            if (filterField.MatchFunction) {
              /* eslint-disable */
                const matchFunction = new Function('fieldValue','userInput=\''+filterField.$value+'\'', filterField.MatchFunction)
                mustMatch[filterField.targetProperty] = matchFunction
                /* eslint-enable */
            } else {
              mustMatch[filterField.targetProperty] = [filterField.$value]
            }
          }
        }
      )

      const mustMatchNum = Object.keys(mustMatch).length

      // change isVisible=true of Objects in primaryList
      // if all properties match the filter
      Object.values(state.primaryList).forEach(
        function (currentItem: any) {
          let propertyMatches = 0
          for (const prop in currentItem) {
            if (mustMatch[prop]) {
              let propertyValue = currentItem[prop]
              // check for matchFunction
              if (typeof mustMatch[prop] === 'function') {
                if (mustMatch[prop].apply(null, [propertyValue])) {
                  propertyMatches++
                }
              } else {
                if (!Array.isArray(propertyValue)) {
                  propertyValue = [propertyValue]
                }
                // no matchFunction, check if (one of) propertyValue is in mustMatch
                for (let i = 0; i < propertyValue.length; i++) {
                  if (mustMatch[prop].indexOf(propertyValue[i]) > -1) {
                    propertyMatches++
                    break
                  }
                }
              }
            }
          }
          currentItem.isVisible = propertyMatches === mustMatchNum
        }
      )
    }
  },
  actions: {
    initializeFromAPI: ({ commit }, apiUrl) => {
      api.getLocations(apiUrl).then((locations: any) => {
        commit('initialize', locations)
        commit('removeLoader')
      })
    },
    setAssetsUrl: ({ commit }, assetsUrl) => {
      commit('setAssetsUrl', assetsUrl)
    },
    setMapBounds: ({ commit }, bounds) => {
      commit('setMapBounds', bounds)
    },
    setProvider: ({ commit }, provider) => {
      commit('setProvider', provider)
    },
    setApiKey: ({ commit }, key) => {
      commit('setApiKey', key)
    },
    setMapOptions: ({ commit }, options) => {
      commit('setMapOptions', options)
    },
    setTileServer: ({ commit }, url) => {
      commit('setTileServer', url)
    },
    loadDetailForItem: ({ commit }, item) => {
      return api.getDetail(item.detailUrl).then((markup: any) => {
        item.detailMarkup = markup
        commit('updateItem', item)
        return item
      })
    }
  },
  getters: {
    apiKey: state => state.apiKey,
    assetsUrl: state => state.assetsUrl,
    currentDetailItem: state => state.currentDetailItem,
    detailVisible: state => state.detailVisible,
    filterModel: state => state.filterModel,
    filterVisible: state => state.filterVisible,
    loading: state => state.loading,
    mapBounds: state => state.mapBounds,
    mapOptions: state => state.mapOptions,
    tileServer: state => state.tileServer,
    provider: state => state.provider,
    visibleItems: state => {
      let visibleItems = []
      visibleItems = Object.values(state.primaryList).filter(
        function (currentItem: any) {
          return currentItem.isVisible
        }
      )
      return visibleItems
    }
  }
})
