import Vue from 'vue'
import App from './App.vue'
import store from './store'

import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'

Vue.component('l-map', LMap)
Vue.component('l-tile-layer', LTileLayer)
Vue.component('l-marker', LMarker)

Vue.config.productionTip = false
const elementId = 'wwt3ListMap'

new Vue({
  store,
  render: h => h(App),
  beforeMount: function () {
    const rootEl = document.getElementById(elementId)
    if (rootEl) {
      const apiUrl = rootEl.getAttribute('data-api-url')
      const assetsBase = rootEl.getAttribute('data-assets-base')
      const boundsFromRootEl = rootEl.getAttribute('data-initial-bounds')
      const gmapsOptionsURL = rootEl.getAttribute('data-gmaps-options')
      const provider = rootEl.getAttribute('data-provider')
      const apiKey = rootEl.getAttribute('data-api-key')
      let tileServer = rootEl.getAttribute('data-tile-server')

      /**
       * Default Tile Server
       */
      if (!tileServer) {
        tileServer = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      }

      if (provider) {
        this.$store.dispatch('setProvider', provider)
      }

      if (gmapsOptionsURL) {
        fetch(gmapsOptionsURL)
          .then(response => response.json())
          .then(data => {
            this.$store.dispatch('setMapOptions', data)
          })
      }

      this.$store.dispatch('setAssetsUrl', assetsBase)
      this.$store.dispatch('setTileServer', tileServer)
      this.$store.dispatch('setApiKey', apiKey)
      this.$store.dispatch('initializeFromAPI', apiUrl)

      // format x1+y1~x2+y2"
      if (boundsFromRootEl) {
        const initialBounds = [{ lat: '0', lng: '0' }, { lat: '1', lng: '1' }]
        const coordsString = decodeURIComponent(boundsFromRootEl.toString())
        const coords = coordsString.split('~')
        if (coords.length === 2) {
          const firstCorner = coords[0].split('+')
          const secondCorner = coords[1].split('+')
          initialBounds[0].lat = firstCorner[0]
          initialBounds[0].lng = firstCorner[1]
          initialBounds[1].lat = secondCorner[0]
          initialBounds[1].lng = secondCorner[1]
        }
        this.$store.dispatch('setMapBounds', initialBounds)
      }
    }
  }
}).$mount('#' + elementId)
